<template>
  <div class="member-by-age-stats">
    <page-header
      title="Membres par âge"
      icon="fas fa-chart-bar"
      :links="getLinks()"
    >
    </page-header>
    <div>
      <b-row>
        <b-col cols="9" ref="printMe">
          <loading-gif :loading-name="statsLoading"></loading-gif>
          <div v-show="!isLoading(statsLoading)" ref="excelMe">
            <div v-if="showIndividuals" style="margin-bottom: 10px">
              <a class="btn-sm btn btn-secondary" @click.prevent="showIndividuals = false">
                <i class="fa fa-chevron-left"></i> Retour
              </a>
              &nbsp;
              <b>{{ showIndividualsLabel }}</b>
            </div>
            <div v-if="showIndividuals && individuals.length === 0" class="empty-text">
              Aucun membre
            </div>
            <table class="table table-bordered" v-if="!showIndividuals">
              <tr>
                <th class="text-left header-odd">âge</th>
                <th
                  v-for="year of displayedSchoolYears" :key="year.id"
                  :colspan="hasUnknown ? 4 : 3"
                  class="text-center"
                  :class="yearClass(year)"
                >
                  {{ year.name }}
                </th>
              </tr>
              <tr>
                <th class="header-odd"></th>
                <th
                  v-for="header of headers"
                  :key="header.index"
                  class="text-center"
                  :class="headerClass(header.index)"
                  :title="header.label"
                >
                  {{ header.name }}
                </th>
              </tr>
              <tr>
                <th></th>
                <th
                  v-for="header of headers"
                  :key="header.index"
                  class="text-center"
                  :class="headerClass(header.index)"
                  :title="header.label"
                >
                  <span v-if="header.name === 'N' && headerSum(header.index) > 0">
                    <a href @click.prevent="showUnknownGender(header)">
                      {{ headerSum(header.index) }}
                    </a>
                  </span>
                  <span v-else>
                    {{ headerSum(header.index) }}
                  </span>
                </th>
              </tr>
              <tr v-for="age of ages" :key="age">
                <td class="text-left cell-odd">
                  <span v-if="isUnknownAge(age)">
                    <a href @click.prevent="showUnknownAge">
                      {{ ageLabel(age) }}
                    </a>
                  </span>
                  <span v-else>
                    {{ ageLabel(age) }}
                  </span>
                </td>
                <td
                  v-for="cell of getAgeRow(age)"
                  :key="cell.index"
                  class="number text-center"
                  :class="cellClas(cell.index)"
                >
                  {{ cell.value }}
                </td>
              </tr>
            </table>
            <table class="table table-bordered" v-else>
              <tr v-for="individual of individuals" :key="individual.id">
                <td>
                  <a class="fake-link" @click.prevent="showIndividualSidebar(individual)">
                    {{ individual.lastAndFirstName() }}
                  </a>
                </td>
                <td>
                  <email-link :email="individual.email"></email-link>
                </td>
                <td>
                  <phone-link :phone="individual.cellPhone"></phone-link>
                </td>
              </tr>
            </table>
          </div>
        </b-col>
        <b-col cols="3">
          <active-school-years-multi-select v-model="selectedSchoolYears"></active-school-years-multi-select>
          <br /><br />
          <members-by-select v-model="selectedMembersBy"></members-by-select>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import ActiveSchoolYearsMultiSelect from '@/components/SchoolYears/ActiveSchoolYearsMultiSelect.vue'
import MembersBySelect from '@/components/Stats/MembersBySelect'
import EmailLink from '@/components/Controls/Links/EmailLink.vue'
import PhoneLink from '@/components/Controls/Links/PhoneLink.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi, openDocument } from '@/utils/http'
import { compareNumbers } from '@/utils/sorting'
import { makeIndividual } from '@/types/people'
import { distinct } from '@/utils/arrays'
import { sum } from '@/utils/math'

export default {
  name: 'members-by-age-stats',
  mixins: [BackendMixin],
  components: {
    PhoneLink,
    EmailLink,
    ActiveSchoolYearsMultiSelect,
    MembersBySelect,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      statsLoading: 'members-by-age',
      selectedSchoolYears: [],
      selectedMembersBy: null,
      statsByYear: new Map(),
      hasUnknown: false,
      agesMap: new Map(),
      rows: [],
      individuals: [],
      showIndividuals: false,
      showIndividualsLabel: '',
    }
  },
  computed: {
    displayedSchoolYears() {
      const schoolYears = [].concat(this.selectedSchoolYears)
      return schoolYears.sort((a, b) => compareNumbers(a.startYear, b.startYear))
    },
    headers() {
      const headers = []
      for (const year of this.displayedSchoolYears) {
        headers.push({ name: 'N', index: headers.length, label: 'Non défini', year: year, })
        headers.push({ name: 'H', index: headers.length, label: 'Hommes', year: year, })
        headers.push({ name: 'F', index: headers.length, label: 'Femmes', year: year, })
        headers.push({ name: 'T', index: headers.length, label: 'Total', year: year, })
      }
      if (!this.hasUnknown) {
        return headers.filter(elt => elt.name !== 'N')
      }
      return headers
    },
    ages() {
      const ages = Array.from(this.agesMap.keys())
      return ages.sort(compareNumbers)
    },
  },
  mounted() {
    this.onLoaded()
  },
  watch: {
    selectedSchoolYears: function() {
      this.onLoaded()
    },
    selectedMembersBy: function() {
      this.onLoaded()
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadStats()
    },
    async showUnknownAge() {
      let individuals = []
      if (this.selectedMembersBy) {
        this.startLoading(this.loadingName)
        for (const year of this.selectedSchoolYears) {
          const url = '/stats/api/members-by-age-not-set/' + this.selectedMembersBy.id + '/' +
            year.id + '/'
          const backendApi = new BackendApi('get', url)
          try {
            const resp = await backendApi.callApi()
            individuals = individuals.concat(resp.data.map(elt => makeIndividual(elt)))
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
        this.individuals = distinct(individuals)
        this.showIndividuals = true
        this.showIndividualsLabel = 'âge à définir'
        this.endLoading(this.loadingName)
      }
    },
    async showUnknownGender(header) {
      this.individuals = []
      if (this.selectedMembersBy) {
        this.startLoading(this.loadingName)
        const url = '/stats/api/members-by-gender-not-set/' + this.selectedMembersBy.id + '/' +
          header.year.id + '/'
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.individuals = this.individuals.concat(resp.data.map(elt => makeIndividual(elt)))
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
      this.showIndividuals = true
      this.showIndividualsLabel = 'sexe non défini'
      this.endLoading(this.loadingName)
    },
    async loadStats() {
      const statsByYear = new Map()
      if (this.selectedMembersBy) {
        this.startLoading(this.loadingName)
        for (const year of this.selectedSchoolYears) {
          const url = '/stats/api/members-by-age-and-gender/' + this.selectedMembersBy.id + '/' +
            year.id + '/'
          const backendApi = new BackendApi('get', url)
          try {
            const resp = await backendApi.callApi()
            statsByYear.set(year.id, resp.data)
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
        this.statsByYear = statsByYear
        this.buildRows()
        this.endLoading(this.loadingName)
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'membres-par-age-et-genre-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'membres-par-age-et-genre-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    ageLabel(age) {
      if (age > 0) {
        return '' + age + ((age > 1) ? ' ans' : ' an')
      } else if (age < 0) {
        return 'à définir'
      }
      return '0 ans'
    },
    isUnknownAge(age) {
      return (age < 0)
    },
    buildRows() {
      const agesMap = new Map()
      const rows = []
      this.hasUnknown = false
      const newRow = new Array(this.displayedSchoolYears.length * 4).fill(0)
      for (let yearIndex = 0; yearIndex < this.displayedSchoolYears.length; yearIndex++) {
        const year = this.displayedSchoolYears[yearIndex]
        const stats = this.statsByYear.get(year.id)
        for (const elt of stats) {
          const yearStartIndex = 4 * yearIndex
          const cellIndex = yearStartIndex + elt.gender
          let row
          const age = elt.age || ''
          if (!agesMap.has(age)) {
            agesMap.set(age, rows.length)
            row = [].concat(newRow)
            rows.push(row)
          } else {
            const rowIndex = agesMap.get(age)
            row = rows[rowIndex]
          }
          row[cellIndex] = elt.count
          if (elt.count && (elt.gender === 0)) {
            this.hasUnknown = true
          }
          // total
          row[yearStartIndex + 3] = row[yearStartIndex] + row[yearStartIndex + 1] + row[yearStartIndex + 2]
        }
      }
      this.rows = rows
      this.agesMap = agesMap
    },
    getAgeRow(age) {
      const rowIndex = this.agesMap.get(age)
      let row = this.rows[rowIndex]
      if (!this.hasUnknown) {
        // supprime l'index 0
        row = row.filter((elt, index) => (index % 4 !== 0))
      }
      return row.map((elt, index) => {
        return { value: elt, index: index, }
      })
    },
    yearClass(year) {
      const yearIndex = this.displayedSchoolYears.map(elt => elt.id).indexOf(year.id)
      return (yearIndex % 2) ? 'header-odd' : ''
    },
    headerClass(index) {
      const yearCols = 4
      const yearIndex = Math.floor(index / yearCols)
      return (yearIndex % 2) ? 'header-odd' : ''
    },
    cellClas(index) {
      const yearCols = this.hasUnknown ? 4 : 3
      const yearIndex = Math.floor(index / yearCols)
      return (yearIndex % 2) ? 'cell-odd' : ''
    },
    headerSum(index) {
      const line = this.rows.map(cell => cell[index])
      return sum(line)
    },
  },
}
</script>

<style scoped lang="less">
.number {
  text-align: right;
}
tr th {
  background: #444;
  color: #fff;
}
tr th a{
  color: #fff !important;
  text-decoration: underline;
}
tr th.header-odd {
 background: #222;
}
tr:nth-of-type(odd) {
  background: #eee;
}
tr:nth-of-type(even) {
  background: #fff;
}
tr:nth-of-type(odd) .cell-odd {
  background: #ccc;
}
tr:nth-of-type(even) .cell-odd {
  background: #ddd;
}
tr td a {
  text-decoration: underline;
}
</style>
